import React from "react"
import { css, jsx } from "@emotion/core"
import { Link } from "gatsby"

import MenuData from "./menuData"

import "./navbarStyles.css"
import Brand from "./brand"
import BurgerMenu from "./burgerMenu"
import CollapseMenu from "./collapseMenu"

function Navbar(props) {
  const items = MenuData

  const navMenu = items.map(item => (
    <li key={item.to.toString}>
      <MenuItem to={item.to} text={item.text} allowDefault={item.allowDefault || false} links={item.links || []} />
    </li>
  ))

  return (
    <>
      <nav class="navBar" role="navigation">
        <div>
          <Link to="/">
            <Brand />
          </Link>
          <ul>{navMenu}</ul>
          <div
            css={css`
              margin: auto 0;
              @media (min-width: 769px) {
                display: none;
              }
            `}
          >
            <BurgerMenu
              navbarState={props.navbarState}
              handleNavbar={props.handleNavbar}
            />
          </div>
        </div>
      </nav>
      <CollapseMenu
        navbarState={props.navbarState}
        handleNavbar={props.handleNavbar}
      />
    </>
  )
}

const MenuItem = props => {

  return (
    <>
      {props.allowDefault
        ? <Link to={props.to} activeStyle={{ color: "#092556" }} partiallyActive={true}>{props.text}</Link>
        : <Link to={props.to} activeStyle={{ color: "#092556" }} partiallyActive={true} onClick={e => e.preventDefault()}>{props.text}</Link>
      }
      
      <SubMenu links={props.links || []} />
    </>
  )
}

function SubMenu(props) {
  const links = props.links

  const linkItems = links.map(link => (
    <li key={link.to.toString()}>
      <Link to={link.to} activeClassName="current">
        {link.text}
      </Link>
    </li>
  ))

  return (
    <div>
      <ul
        css={css`
          margin-top: 4px;
        `}
      >
        {linkItems}
      </ul>
    </div>
  )
}

export default Navbar
