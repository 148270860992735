const MenuData = [
  {
    to: "/about",
    text: "About",
    links: [
      { to: "/about", text: "The Chamber" },
      { to: "/about/team", text: "Our Team" },
      { to: "/about/membership", text: "Membership" },
      { to: "/about/calendar", text: "Calendar" },
    ],
  },
  {
    to: "/industry",
    text: "Industry",
    links: [
      { to: "/industry/membership-directory", text: "Membership Directory" },
      {
        to: "/industry/downtown-development-authority",
        text: "Downtown Development",
      },
      {
        to: "/industry/ecconomic-development-authority",
        text: "Economic Development",
      },
    ],
  },
  {
    to: "/community",
    text: "Community",
    links: [
      { to: "/community/government", text: "Government" },
      { to: "/community/education", text: "Education" },
      { to: "/community/healthcare", text: "Healthcare" },
      { to: "/community/recreation", text: "Recreation" },
      { to: "/community/attractions", text: "Attractions" },
    ],
  },
  {
    to: "/programs",
    text: "Programs",
    links: [
      { to: "/programs/leadership-evans", text: "Leadership Evans" },
      { to: "/programs/junior-board", text: "Junior Board" },
      { to: "/programs/business-after-hours", text: "Business After Hours" },
      { to: "/programs/chamber-banquet", text: "Chamber Banquet" },
      {
        to: "/programs/claxton-wildlife-festival",
        text: "Rattlesnake & Wildlife Festival",
      },
      {
        to: "/programs/national-day-of-prayer",
        text: "National Day of Prayer",
      },
      {
        to: "/programs/rotary-chamber-auction",
        text: "Rotary/Chamber Auction",
      },
      {
        to: "/programs/trick-or-treat",
        text: "Trick or Treat - Kid's Safety Day",
      },
      {
        to: "/programs/cruisin-in-the-country",
        text: "Cruisin’ in the Country",
      },
      { to: "/programs/christmas-in-claxton", text: "Christmas in Claxton" },
    ],
  },
  { to: "/contact", text: "Contact", allowDefault: "true"},
  { to: "/join", text: "Join Now", allowDefault: "true"},
]

export default MenuData
