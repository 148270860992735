import React from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import { Link } from "gatsby"
import { css, jsx } from "@emotion/core"

import MenuData from "./menuData"

function CollapseMenu(props) {
  const items = MenuData

  const linkItems = items.map(item => (
    <li key={item.to.toString}>
      <MenuItem
        to={item.to}
        text={item.text}
        links={item.links || []}
        handleNavbar={props.handleNavbar}
      />
    </li>
  ))

  const { open } = useSpring({ open: props.navbarState ? 0 : 1 })

  if (props.navbarState === true) {
    return (
      <CollapseWrapper
        style={{
          transform: open
            .interpolate({
              range: [0, 0.2, 0.3, 1],
              output: [0, -20, 0, -200],
            })
            .interpolate(openValue => `translate3d(0, ${openValue}px, 0`),
        }}
      >
        <NavLinks>{linkItems}</NavLinks>
      </CollapseWrapper>
    )
  }
  return null
}

class MenuItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  handleClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    const isOpen = this.state.isOpen

    if (this.props.links && this.props.links.length) {
      return (
        <>
          <a onClick={this.handleClick}>{this.props.text}</a>
          <ul
            style={{ display: isOpen ? "block" : "none", marginLeft: "20px" }}
          >
            <CSubMenu links={this.props.links} />
          </ul>
        </>
      )
    } else {
      return <Link to={this.props.to}>{this.props.text}</Link>
    }
  }
}

function CSubMenu(props) {
  const links = props.links

  const linkItems = links.map(link => (
    <li key={link.to.toString()}>
      <Link
        to={link.to}
        activeStyle={{ color: "red" }}
        onClick={props.handleNavnar}
      >
        {link.text}
      </Link>
    </li>
  ))

  return <ul>{linkItems}</ul>
}

export default CollapseMenu

const CollapseWrapper = styled(animated.div)`
  background: #2d3436;
  position: fixed;
  top: 4.5rem;
  left: 0;
  right: 0;
  z-index: 100;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`

const NavLinks = styled.ul`
  list-style-type: none;
  padding: 2rem 1rem 2rem 2rem;
  & li {
    transition: all 300ms linear 0s;
  }
  & a {
    font-size: 1.4rem;
    line-height: 2;
    color: #dfe6e9;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: red;
      border-bottom: 1px solid red;
    }
  }
`
